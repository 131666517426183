.notification_form,
.notification_data_cont {
    background-color: #fff;
    padding: 20px 15px;
    border-radius: 15px;
    margin-top: 15px;
}

.notification_form .MuiFormControl-root {
    margin-top: 20px;
}

.notification_form .MuiFormControl-root:first-of-type {
    margin-top: 0;
}

.notification_form>button {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    background-color: #00235F !important;
}

.notification_form .MuiAlert-root {
    margin-top: 20px;
}

.notification_data_cont {
    margin-top: 25px;
    background-color: #fff;
}

.notification_card_cont {
    margin-top: 15px;
    background-color: #00235fc2;
    padding: 15px;
    border-radius: 20px 10px;
}

.notification_card_cont>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.notification_card_cont>div>h2 {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 7px;
    cursor: default;
    flex: 1;
}

.notification_card_cont>p {
    white-space: pre-line;
    color: #ddd;
    font-size: 1rem;
}

.notification_data_cont .MuiCircularProgress-root {
    display: block;
    margin: auto;
    margin-top: 15px;
    color: #00235F;
}