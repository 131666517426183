#sidenav {
    flex: 0.3;
    height: calc(100vh - 40px);
    max-height: 100vh;
    background-color: #00235F;
    padding: 20px;
    overflow: hidden;
    width: 100%;
    max-width: 250px;
    position: fixed;
}

.sidenav_cont > h3 {
    text-align: center;   
    font-size: 30px;
    color: #fff;
    margin-bottom: 25px;
}

.sidenav_listItem_cont {
    min-height: calc(100vh - 110px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidenav_listItems {
    flex: 1;
}

.sidenav_listItem_cont .MuiButtonBase-root {
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
}

.sidenav_listItem_active {
    color: #F9BA00 !important;
}

.sidenav_listItem_cont > button {
    display: block;
    margin: auto;
    font-size: 16px;
    font-weight: 600 !important;
    letter-spacing: 2px;
}