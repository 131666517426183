.dashboard_cont {
    min-height: calc(100vh - 40px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -96.98%, #F9BA00 95.57%);
    padding: 20px;
}

.dashboard_tripData_cont {
    margin-bottom: 25px;
}

.dashboard_tripData_cont>h4 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.dashboard_tripData_cont_data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 15px;
    text-align: center;
}

.dashboard_tripData_cont_data>div {
    background-color: #fff;
    border-radius: 25px;
    padding: 10px;
    font-weight: 500;
    min-height: 75px;
    display: grid;
    place-items: center;
}

.dashboard_tripData_cont_data>div>p {
    font-size: 1.1rem;
}

.dashboard_tripData_cont_data>div>p>span {
    display: block;
    font-size: 1rem;
    color: #00235F;
}

.dashboard_FromToDate_cont {
    display: flex;
    align-items: end;
}

.dashboard_FromToDate_cont>div {
    margin-right: 20px;
}

.dashboard_FromToDate_cont>div>h5 {
    font-weight: 400;
    font-size: 16px;
    color: #222;
}

.dashboard_tripData_cont>p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
    color: #00235F;
}